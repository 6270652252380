import { makeStyles } from '@material-ui/core';
import { COLOR1 } from '../../constants';

export const LoaderStyles = () =>
  makeStyles(
    (theme) => ({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(233, 233, 235, 0.60)',
      },

      center_style: {
        display: 'flex',
        justifyContent: 'center',
      },

      loading: {
        font: 'normal normal bold 30px/34px Arial',
        letterSpacing: '0.21px',
        color: COLOR1,
        opacity: 1,
        marginTop: '4px',
        paddingLeft: '32px',
        [theme.breakpoints.down('600')]: {
          font: ' normal normal bold 22px/34px Arial',
          paddingLeft: '22px',
        },
      },

      loaderStyle: {
        width: '114px',
        [theme.breakpoints.down('600')]: {
          width: '90px',
        },
      },

      loader_circle: {
        width: '105px',
        [theme.breakpoints.down('600')]: {
          width: '90px',
        },
      },
    }),
    { index: 1 }
  );
