export const businessMapping = (productName) => {
  const map = new Map();
  const businessProdName = (prodName) => {
    if (productName?.toLowerCase() === prodName?.toLowerCase()) {
      map.set(productName, prodName);
    }
  };
  businessProdName('Ahorro Comercial');
  businessProdName('CD Comercial');
  businessProdName('SIN FINES DE LUCRO');
  businessProdName('ESCROW ACCOUNT');
  businessProdName('My Biz Account');

  if (productName?.toLowerCase() === 'Commercial CD'.toLowerCase()) {
    map.set('Commercial CD', 'CD Comercial');
  }
  if (productName?.toLowerCase() === 'NON-PROFIT'.toLowerCase()) {
    map.set('NON-PROFIT', 'SIN FINES DE LUCRO');
  }

  if (productName?.toLowerCase() === 'Commercial Loan'.toLowerCase()) {
    map.set('Commercial Loan', 'Prestamo Comercial');
  }
  if (productName?.toLowerCase() === 'Préstamo Comercial'.toLowerCase()) {
    map.set('Préstamo Comercial', 'Prestamo Comercial');
  }
  if (productName?.toLowerCase() === 'Prestamo Comercial'.toLowerCase()) {
    map.set('Prestamo Comercial', 'Prestamo Comercial');
  }
  if (productName?.toLowerCase() === 'Commercial Credit Card'.toLowerCase()) {
    map.set('Commercial Credit Card', 'Tarjeta de Credito Comercial');
  }
  if (productName?.toLowerCase() === 'Tarjeta de Crédito Comercial'.toLowerCase()) {
    map.set('Tarjeta de Crédito Comercial', 'Tarjeta de Credito Comercial');
  }
  if (productName?.toLowerCase() === 'Tarjeta de Credito Comercial'.toLowerCase()) {
    map.set('Tarjeta de Credito Comercial', 'Tarjeta de Credito Comercial');
  }
  if (productName?.toLowerCase() === 'Commercial Line of Credit'.toLowerCase()) {
    map.set('Commercial Line of Credit', 'Linea de Credito Comercial');
  }
  if (productName?.toLowerCase() === 'Línea de Crédito Comercial'.toLowerCase()) {
    map.set('Línea de Crédito Comercial', 'Linea de Credito Comercial');
  }
  if (productName?.toLowerCase() === 'Linea de Credito Comercial'.toLowerCase()) {
    map.set('Linea de Credito Comercial', 'Linea de Credito Comercial');
  }

  return map.get(productName);
};
