/* eslint-disable max-lines */
import { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SubmissionPageStyles } from './SubmissionPageStyles';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import ErrorImage from '../../images/error-icon.png';
import { getAccessToken } from '../../services/tokenService';
import { clearContext } from '../clearContext';
import { clearDoChecklistContext } from '../CommercialDeposit/DragDropFiles/DocLabelTypes';

const useStyles = SubmissionPageStyles();

const SubmissionFailPage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { history, resetErrorBoundary, isErrorBoundary } = props;
  const { isQR } = leadGenerationContext;

  useEffect(() => {
    leadGenerationContext.pauseTimerAndClearInterval();
    leadGenerationContext.clearPingTimer();
  }, []);

  useEffect(() => {
    let unListen = null;
    unListen = history.listen(() => {
      if (history.action === 'POP') {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          clearDoChecklistContext(leadGenerationContext);
          clearContext(leadGenerationContext);

          history.push('/');
        } else {
          let url = '';
          if (leadGenerationContext.location === 'PR') {
            url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}`;
          } else if (leadGenerationContext.location === 'VI') {
            url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}/${leadGenerationContext.location?.toLowerCase()}`;
          } else {
            url = `https://orientalbank.com/`;
          }
          window.location.href = url;
        }
      }
    });
    return unListen;
  }, []);

  const handleRoute = async () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      clearDoChecklistContext(leadGenerationContext);
      clearContext(leadGenerationContext);

      const token = await getAccessToken();
      if (token) {
        leadGenerationContext.startTimer();
        leadGenerationContext.pingTimer(token);
        leadGenerationContext.setAccessToken(token);
        history.push('/');
      }
    } else {
      let url = '';
      if (isQR) {
        url = `https://orientalbank2016.sharepoint.com/sites/OrientalIntranet/SitePages/External-web-links.aspx`;
      } else if (leadGenerationContext.location === 'PR') {
        url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}`;
      } else if (leadGenerationContext.location === 'VI') {
        url = `https://orientalbank.com/${leadGenerationContext.language?.toLowerCase()}/${leadGenerationContext.location?.toLowerCase()}`;
      } else {
        url = `https://orientalbank.com/`;
      }
      window.location.href = url;
    }
  };

  const handleRestart = async () => {
    clearContext(leadGenerationContext);
    const token = await getAccessToken();
    if (token) {
      leadGenerationContext.pingTimer(token);
      leadGenerationContext.setAccessToken(token);
      leadGenerationContext.startTimer();
      history.push(
        `/?ao_productType=${leadGenerationContext.productType}&ao_productName=${leadGenerationContext.productName}&ao_location=${leadGenerationContext.location}&ao_language=${leadGenerationContext.language}&utm_campaign=${leadGenerationContext.utmCampaign}&utm_source=${leadGenerationContext.utmSource}&utm_medium=${leadGenerationContext.utmMedium}&utm_content=${leadGenerationContext.utmContent}&utm_term=${leadGenerationContext.utmTerm}`
      );
      resetErrorBoundary();
    }
  };

  return (
    <div
      role="main"
      aria-describedby="screenSubmitHeading screenSubmitHeadingDesc screenSubmitHeadingDescFail"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
          className={classes.imgGrid}
        >
          <img src={ErrorImage} className={classes.error} alt="Submission Failed" />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography id="screenSubmitHeadingDescFail" className={classes.errorTitle}>
            {t('Sorry we hit a snag. Please try submitting your application later.', {
              defaultValue: 'Sorry we hit a snag. Please try submitting your application later.',
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {isErrorBoundary ? (
            <Button
              disableFocusRipple
              autoFocus
              className={classes.returnToOrientalInError}
              data-testid="ReturnToOrientalButtonTitle"
              title="ReturnToOrientalButtonTitle"
              onClick={handleRestart}
            >
              {t('Restart my application', { defaultValue: 'Restart my application' })}
            </Button>
          ) : (
            <Button
              disableFocusRipple
              autoFocus
              className={classes.returnToOrientalInError}
              data-testid="ReturnToOrientalButtonTitle"
              title="ReturnToOrientalButtonTitle"
              onClick={handleRoute}
            >
              {t('Return to orientalbank.com', { defaultValue: 'Return to orientalbank.com' })}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SubmissionFailPage.propTypes = {
  isErrorBoundary: PropTypes.bool,
  resetErrorBoundary: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    action: PropTypes.string,
  }),
};

export default withRouter(SubmissionFailPage);
