export const countryEspTranslation = {
  Aruba: 'Aruba',
  Argentina: 'Argentina',
  Australia: 'Australia',
  Brazil: 'Brasil',
  Canada: 'Canadá',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  'Costa Rica': 'Costa Rica',
  Cuba: 'Cuba',
  Germany: 'Alemania',
  'Dominican Republic': 'República Dominicana',
  Egypt: 'Egipto',
  Spain: 'España',
  France: 'Francia',
  'United Kingdom of Great Britain and Notherm Ireland': 'Reino Unido',
  Greece: 'Grecia',
  Guatemala: 'Guatemala',
  'Hong Kong Special Administrative Regio of China': 'Hong King',
  Haiti: 'Haití',
  Italy: 'Italia',
  Jamaica: 'Jamaica',
  Mexico: 'México',
  "Democratic People's Republic of Korea": 'Corea del Norte',
  Portugal: 'Portugal',
  'Russian Federation': 'Rusia',
  'United States of America': 'Estados Unidos',
  Venezuela: 'Venezuela',
  'United States Virgin Islands': 'Islas Vírgenes de los Estados Unidos',
  Poland: 'Polonia',
  'Channel Islands': 'Islas del Canal',
  Afghanistan: 'Afganistán',
  Angola: 'Angola',
  Anguilla: 'Anguila',
  'Aland Islands': 'Islas Aland',
  Albania: 'Alabania',
  Andorra: 'Andorra',
  'Netherlands Antilles': 'Antillas Holandesas',
  'United Arab Emirates': 'Emiratos Árabes Unidos',
  Armenia: 'Armenia',
  'American Samoa': 'Samoa Americana',
  'Antigua and Barbuda': 'Antigua y Barbuda',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaiyán',
  Burundi: 'Burundi',
  Belgium: 'Bélgica',
  Benin: 'Benin',
  'Bonaire, Saint Eustatius and Saba': 'Bonaire, San Eustaquio y Saba',
  'Burkina Faso': 'Burkina Faso',
  Bangladesh: 'Bangladesh',
  Bulgaria: 'Bulgaria',
  Bahrain: 'Baréin',
  Bahamas: 'Bahamas',
  'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
  Belarus: 'Bielorrusia',
  Belize: 'Belice',
  Bermuda: 'Bermuda',
  Bolivia: 'Bolivia',
  Barbados: 'Barbados',
  'Brunei Darussalam': 'Brunéi Darussalam',
  Bhutan: 'Bután',
  Botswana: 'Botsuana',
  'Central African Republic': 'República de África Central',
  Switzerland: 'Suiza',
  'Côte divoire': 'Costa de Marfil',
  Cameroon: 'Camrún',
  'Democratic Republic of the Congo': 'República Democrática del Congo',
  Congo: 'Congo Belga',
  'Cook Islands': 'Islas Cook',
  Comoros: 'Comoras',
  'Cape Verde': 'Cabo Verde',
  Curacao: 'Curazao',
  'Cayman Islands': 'Islas Caimán',
  Cyprus: 'Chipre',
  'Czech Republic': 'Chequia',
  Djibouti: 'Yibuti',
  Dominica: 'Dominica',
  Denmark: 'Dinamarca',
  Algeria: 'A;geria',
  Ecuador: 'Ecuador',
  Eritrea: 'Eritrea',
  'Western Sahara': 'Sahara Occidental',
  Estonia: 'España',
  Ethiopia: 'Estonia',
  Finland: 'Finlandia',
  Fiji: 'Fiyi',
  'Falkland Islands (Malvinas)': 'Islas Malvinas',
  'Faeroe Islands': 'Islas Feroe',
  'Federated States of Micronesia': 'Estados Federados de Micronesia',
  Gabon: 'Gabón',
  Georgia: 'Georgia',
  Guernsey: 'Guernsey',
  Ghana: 'Ghana',
  Gilbraltar: 'Gilbraltar',
  Guinea: 'Guinea',
  Guadeloupe: 'Guadalupe',
  Gambia: 'Gambia',
  'Guinea-Bissau': 'Guinea-Bisáu',
  'Equatorial Guinea': 'Guinea Ecuatorial',
  Grenada: 'Granada',
  Greenland: 'Groenlandia',
  'French Guiana': 'Guyana Francesa',
  Guam: 'Guam',
  Guyana: 'Guyana Francesa',
  Honduras: 'Honduras',
  Croatia: 'Croacia',
  Hungary: 'Hungría',
  Indonesia: 'Indonesia',
  'Isle of Man': 'Isla de Man',
  India: 'India',
  Ireland: 'Irlanda',
  'Iran, Islamic Republic of': 'Irán',
  Iraq: 'Irak',
  Iceland: 'Islandia',
  Israel: 'Israel',
  Jersey: 'Jersey',
  Jordan: 'Jordania',
  Japan: 'Japón',
  Kazakhstan: 'Kazajistán',
  Kenya: 'Kenia',
  Kyrgyzstan: 'Kirguistán',
  Cambodia: 'Camboya',
  Kiribati: 'Kiribati',
  'Saint Kitts and Nevis': 'San Cristóbal y Nieves',
  'Republic of Korea': 'República de Korea',
  Kuwait: 'Kuwait',
  "Lao People's Democratic Republic": 'Laos',
  Lebanon: 'Líbano',
  Liberia: 'Liberia',
  'Libyan Arab Jamahiriya': 'Libia',
  'Saint Lucia': 'Santa Lucía',
  Liechtenstein: 'Liechtenstein',
  'Sri Lanka': 'Sri Lanka',
  Lesotho: 'Lesoto',
  Lithuania: 'Lituania',
  Luxembourg: 'Luxemburgo',
  Latvia: 'Letonia',
  'Macao Special Administrative Region of China': 'Macao',
  Morocco: 'Marruecos',
  Monaco: 'Mónaco',
  'Republic of Moldova': 'Moldavia',
  Madagascar: 'Madagascar',
  Maldives: 'Maldivas',
  'Marshall Islands': 'Islas Marshall',
  'The former Yugoslav Republic of Macedonia': 'Macedonia del Norte',
  Mali: 'Malí',
  Malta: 'Malta',
  Myanmar: 'Myanmar (Birmania)',
  Montenegro: 'Montenegro',
  Mongolia: 'Mongolia',
  'Northern Mariana Islands': 'Islas Marianas del Norte',
  Mozambique: 'Mozambique',
  Mauritania: 'Mauritania',
  Montserrat: 'Montserrat',
  Martinique: 'Martinica',
  Mauritius: 'Mauricio',
  Malawi: 'Malaui',
  Malaysia: 'Malasia',
  Mayotte: 'Mayotte',
  Namibia: 'Namibia',
  'New Caledonia': 'Nueva Caledonia',
  Niger: 'Níger',
  'Norfolk Island': 'Isla Norfolk',
  Nigeria: 'Nigeria',
  Nicaragua: 'Nicaragua',
  Niue: 'Niue',
  Netherlands: 'Paises Bajos',
  Norway: 'Noruega',
  Nepal: 'Nepal',
  Nauru: 'Nauru',
  'New Zealand': 'Nueva Zelanda',
  Oman: 'Omán',
  Pakistan: 'Pakistán',
  Panama: 'Panamá',
  Pitcaim: 'Islas Pitcairn',
  Peru: 'Perú',
  Philippines: 'Filipinas',
  Palau: 'Palaos',
  'Papa New Guinea': 'Papúa Nueva Guinea',
  'Puerto Rico': 'Puerto Rico',
  Paraguay: 'Paraguay',
  'Occupied Palestinian Territory': 'Territorios Palestinos',
  'French Polynesia': 'Polinesia Francesa',
  Qatar: 'Catar',
  Reunion: 'Reunión',
  Romania: 'Rumanía',
  Rwanda: 'Ruanda',
  'Saudi Arabia': 'Arabia Saudita',
  Sudan: 'Sudán',
  Senegal: 'Senegal',
  Singapore: 'Singapur',
  'Saint Helena': 'Santa Helena',
  'Svalbard and Jan Mayen Islands': 'Svalbard y Jan Mayen',
  'Solomon Islands': 'Islas Salomón',
  'Sierra Leone': 'Sierra Leona',
  'El Salvador': 'El Salvador',
  'San Marino': 'San Marino',
  Somalia: 'Somalia',
  'Saint Pierre and Miquelon': 'San Pedro y Miquelón',
  Serbia: 'Serbia',
  'Sao Tome and Principe': 'Santo Tomé y Príncipe',
  Suriname: 'Surinam',
  Slovakia: 'Eslovaquia',
  Slovenia: 'Eslovenia',
  Sweden: 'Suecia',
  Swaziland: 'Esuatini',
  'Sint Maarten': 'Sint Maarten',
  Seychelles: 'Seychelles',
  'Syrian Arab Republic': 'Siria',
  'Turks and Caicos Islands': 'Islas Turcas y Caicos',
  Chad: 'Chad',
  Togo: 'Togo',
  Thailand: 'Tailandia',
  Tajikistan: 'Tayikistán',
  Tokelau: 'Tokelau',
  Turkmenistan: 'Turkmenistán',
  'Timor-Leste': 'Timor Oriental',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinidad y Tobago',
  Tunisia: 'Túnez',
  Turkey: 'Turquía',
  Tuvalu: 'Tuvalu',
  Taiwan: 'Taiwán',
  'United Republic of Tanzania': 'Tanzania',
  Uganda: 'Uganda',
  Ukraine: 'Ucrania',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistán',
  'Holy See': 'Santa Sede',
  'Saint Vincent and the Grenadines': 'San Vicente y las Granadinas',
  'British Virgin Islands': 'Islas Vírgenes Británicas',
  'Viet Nam': 'Vietnam',
  Vanuatu: 'Vanuatu',
  'Wallis and Futuna Islands': 'Wallis y Futuna',
  Samoa: 'Samoa',
  Yemen: 'Yemen',
  'South Africa': 'Sudáfrica',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabue',
  'To which countries do you expect to make or receive your transfer?':
    '¿A qué países esperas realizar o recibir sus transferencias?',
  'Select max of 10 countries': 'Seleccione un máximo de 10 países',
  Search: 'Buscar',
  'Selected Countries ': 'Países seleccionados ',
};
