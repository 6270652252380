export const productTypeMapping = (productType) => {
  const map = new Map();
  map.set('Checking Account', 'Checking Account');
  map.set('Savings Account', 'Savings Account');
  map.set('Certificate of Deposit', 'Certificate of Deposit');
  map.set('IRA', 'IRA');
  map.set('Deposit Certificate', 'Deposit Certificate');
  map.set('Credit', 'Credit');
  return map.get(productType);
};
