export const typeCorporationDbaPsc = [
  {
    label: 'Corporate Resolution',
    name: 'corpResolution',
    id: 1,
  },
  {
    label: 'Certificate of Incorporation',
    name: 'certIncorp',
    id: 2,
  },
  {
    label: 'Articles of Incorporation',
    name: 'articleIncorp',
    id: 3,
  },
  {
    label: 'List of Directors and Officers',
    name: 'listOfOfficers',
    id: 4,
  },
  {
    label: 'Commercial Registration Certificate',
    name: 'regCertificate',
    id: 5,
  },
];

export const typeDba = [
  {
    label: 'DBA Resolution',
    name: 'dbaResolution',
    id: 1,
  },
  {
    label: 'Commercial Registration Certificate',
    name: 'regCertificate',
    id: 2,
  },
];

export const typeLlc = [
  {
    label: 'LLC Resolution',
    name: 'LlcResolution',
    id: 1,
  },
  {
    label: 'Certificate of Organization',
    name: 'certOfOrg',
    id: 2,
  },
  {
    label: 'Operational Agreement',
    name: 'opAgreement',
    id: 3,
  },
  {
    label: 'List of Directors and Officers',
    name: 'listOfOfficers',
    id: 4,
  },
  {
    label: 'Commercial Registration Certificate',
    name: 'regCertificate',
    id: 5,
  },
];
export const typeTrust = [
  {
    label: 'Deed of Trust',
    name: 'deedOfTrust',
    id: 1,
  },
  {
    label: 'Certification for Trust Registry',
    name: 'certOfTrustReg',
    id: 2,
  },
  {
    label: 'Certificate of Incumbency',
    name: 'certOfIncumbency',
    id: 3,
  },
  {
    label: 'Commercial Registration Certificate',
    name: 'regCertificate',
    id: 4,
  },
];

export const typeEmployedOROreintalBankEmployee = [
  {
    label: 'Last two (2) checkbooks or direct payroll deposit (no more than 20 days)',
    name: 'directPayrollDeposit',
    id: 1,
  },
  {
    label: 'Employment Certification',
    name: 'employmentCertification',
    id: 2,
  },
  // {
  //     label: 'Employment Certification',
  //     name: 'employmentCertification',
  //     id: 2,
  //   : ''
  // }
];

export const typeRetired = [
  {
    label: 'Last Bank Statement which reflect the benefit received',
    name: 'lastBankStatementBenifirReceived',
    id: 1,
  },
];

export const typeSelfEmployed = [
  {
    label: 'PR Customers: Income Return Tax Form (no federal)',
    name: 'IncomeReturnTaxFormPRCust',
    id: 1,
  },
  {
    label: 'USVI Customers: Income Return Tax Form (federal)',
    name: 'IncomeReturnTaxFormUSVICust',
    id: 2,
  },
];

export const typeEmpOROreintalBankEmpOthers = [
  {
    label: 'A recent paystub or payroll summary that includes the cumulative one during the year',
    name: 'payStubOrPayrollDepositCumulative',
    id: 1,
  },
  {
    label: 'Bank Statement which reflect the payroll deposit',
    name: 'payrollDepositBankStatement',
    id: 2,
  },
  {
    label: 'ASUME: certification letter and deposit history',
    name: 'certificationLetterAndDepositHistory',
    id: 3,
  },
];

export const typeRetiredOthers = [
  {
    label: 'Copy of Social Security letter with the amount received',
    name: 'socialSecurityCopyOfReceivedAmt',
    id: 1,
  },
  {
    label: 'ASUME: certification letter and deposit history',
    name: 'certLetterAndDepositHistory',
    id: 2,
  },
];
export const clearDoChecklistContext = (context) => {
  context.setUploadedFiles({});
  context.setProgress({
    corpResolution: 0,
    certIncorp: 0,
    articleIncorp: 0,
    listOfOfficers: 0,
    regCertificate: 0,
    dbaResolution: 0,
    LlcResolution: 0,
    certOfOrg: 0,
    opAgreement: 0,
    deedOfTrust: 0,
    certOfTrustReg: 0,
    certOfIncumbency: 0,
  });
  context.setDocLabels([]);
  context.setUploadDocId({
    corpResolution: '',
    certIncorp: '',
    articleIncorp: '',
    listOfOfficers: '',
    regCertificate: '',
    dbaResolution: '',
    LlcResolution: '',
    certOfOrg: '',
    opAgreement: '',
    deedOfTrust: '',
    certOfTrustReg: '',
    certOfIncumbency: '',
  });
  context.setUploadStatus({
    corpResolution: '',
    certIncorp: '',
    articleIncorp: '',
    listOfOfficers: '',
    regCertificate: '',
    dbaResolution: '',
    LlcResolution: '',
    certOfOrg: '',
    opAgreement: '',
    deedOfTrust: '',
    certOfTrustReg: '',
    certOfIncumbency: '',
  });
  context.setIsDocChecklistDirty(false);
};
