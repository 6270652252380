import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (
  process.env.REACT_APP_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'development'
) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'ds-jss',
});

ReactDOM.render(
  <BrowserRouter>
    <StylesProvider generateClassName={generateClassName}>
      <App />
    </StylesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
