export const OccupationListEN = () => [
  'Attorney/ Notary',
  'Insurance Agent',
  'Surveyor',
  'Agronomist/ Farmer',
  'Adjuster',
  'Housewife',
  'Arquitect',
  'Artist',
  'Graphic Artist',
  'Auditor',
  'Banker',
  'Barber/ Stylist',
  'Bartender',
  'Lottery Ticket Seller',
  'Fireman',
  'Broker',
  'Trucker',
  'Chef',
  'Scientist/ Biologist/ Chemist',
  'Trader',
  'Financial Consultant',
  'Accountant/ CPA',
  'Contractor',
  'Real Estate Broker',
  'Dentist/ Orthodoncist',
  'Developer',
  'Unemployed',
  'Detective/ Investigator',
  'Business Owner',
  'Electrician',
  'Cleaning Employee',
  'State Government Employee',
  'Federal Government Employee',
  'Nurse',
  'Beautician',
  'Student',
  'Pharmacist',
  'Manager',
  'Security Guard/ Police',
  'Handyman',
  'Engineer',
  'Investigador/ Detective',
  'Jewelry',
  'Judge',
  'Teacher/ Professor',
  'Mechanic',
  'Doctor',
  'Messanger',
  'Soldier',
  'Pastor/ Priest',
  'Administrative Personnel',
  'Plumber',
  'Politician',
  'President/ VP/ Executive',
  'Computer Programmer',
  'Medical Propagandist',
  'Pshycologist/ Social Worker',
  'Public Relations',
  'Appraiser',
  'Medical Technician',
  'Seller',
  'Auto Dealer',
  'Veteran',
  'Veterinary',
  'Minor',
  'Artesan',
  'Singer',
  'Administrative Assistant',
  'Sportsman',
  'Director',
  'Waiter',
  'Clerk',
  'Operator',
  'Journalist',
  'Promotions',
  'Receptionist',
  'Retired/ Pensioned',
  'Secretary',
  'Customer Service Agent',
  'Supervisor',
  'Company Administrator',
  'Lottery Agent',
  'Farmer/ Fisherman',
  'Construction Employee',
  'Diplomatic',
  'Housekeeper',
  'Stewardess/ Model',
  'Butcher',
  'Carpenter',
  'Professor',
  'Chofer/ Taxi Driver',
  'Child Care',
  'Docker',
  'Floricultist',
  'Tourist Guide',
  'Tinsmith',
  'Independent',
  'Landscaping/ Maintenance',
  'Police/ Security',
  'Public Carrier',
  'Marketing Professional',
  'Art Professional',
  'Investor',
  'Welder',
  'Technician',
  'Nail Technician',
  'Captain',
  'Pilot',
];

export const OccupationListES = () => [
  'Abogado/Notario',
  'Agente de Seguros',
  'Agrimensor',
  'Agrónomo/ Agricultor',
  'Ajustador',
  'Ama de Casa',
  'Arquitecto',
  'Artista/ Artesano',
  'Artista Gráfico',
  'Auditor',
  'Banquero',
  'Barbero/ Estilista',
  'Bartender',
  'Billetero Lotería',
  'Bombero',
  'Broker',
  'Camionero/ Gruero',
  'Chef/ Cocinero',
  'Cientifíco/ Biólogo/ Químico',
  'Comerciante',
  'Consultor Financiero',
  'Contador/ CPA',
  'Contratista',
  'Corredor Real Estate',
  'Dentista/ Ortodoncista',
  'Desarrollador',
  'Desempleado',
  'Detective/ Investigador',
  'Dueño Negocio Propio',
  'Electricista',
  'Empleado de Limpieza',
  'Empleado Gobierno Estatal',
  'Empleado Gobierno Federal',
  'Enfermera',
  'Esteticista',
  'Estudiante',
  'Farmacéutico',
  'Gerente',
  'Guardia Seguridad/ Policia',
  'Handyman/ Obrero',
  'Ingeniero',
  'Investigador/ Detective',
  'Joyero',
  'Juez/ Fiscal',
  'Maestro/ Profesor',
  'Mecánico',
  'Médico',
  'Mensajero',
  'Militar',
  'Pastor/ Sacerdote',
  'Personal Administrativo',
  'Plomero',
  'Político',
  'Presidente/ VP/ Ejecutivo',
  'Programador Computadoras',
  'Propagandista Médico',
  'Psicólogo/ Trabajador Social',
  'Relacionista Público',
  'Tasador',
  'Tecnólogo Médico',
  'Vendedor',
  'Vendedor de Autos',
  'Veterano',
  'Veterinario',
  'Menor',
  'Artesano',
  'Artista/ Cantante',
  'Asistente Administrativa',
  'Deportista',
  'Director',
  'Mesero',
  'Oficinista',
  'Operador',
  'Periodista',
  'Promociones',
  'Recepcionista',
  'Retirado/ Pensionado',
  'Secretaria',
  'Servicio al Cliente',
  'Supervisor',
  'Administrador Empresa',
  'Agente Lotería',
  'Agricultor/ Pescador',
  'Empleado Construcción',
  'Diplomático',
  'Ama de Llaves',
  'Azafata/ Modelo',
  'Carnicero',
  'Carpintero',
  'Catedrático/ Profesor Universitario',
  'Chofer/ Taxista',
  'Cuido Niños',
  'Estibador',
  'Floricultor',
  'Guía Turístico',
  'Hojalatero',
  'Independiente',
  'Jardinero/ Mantenimiento',
  'Policía/ Seguridad',
  'Porteador Público',
  'Profesional Mercadeo',
  'Profesionales Arte',
  'Rentas/ Inversionista',
  'Soldador',
  'Técnico/ Diestro',
  'Técnico de Uñas',
  'Capitan',
  'Piloto',
];
