export const industryTypeListEN = () => [
  'Farming',
  'Auto / Transportation',
  'Real estate',
  'Communications',
  'Building',
  'Entertainment & Art',
  'Finance & Insurance',
  'Government',
  'Manufacturing / Distribution',
  'Restaurant / Food',
  'Health',
  'Services',
  'Professional services',
  'Tourism',
  'Bars / Pubs / Clubs',
  'Appointment Services',
  'Retail Sales',
];

export const industryTypeListES = () => [
  'Agricultura',
  'Auto / Transporte',
  'Bienes Raíces',
  'Comunicaciones',
  'Construcción',
  'Entretenimiento & Arte',
  'Finanzas & Seguros',
  'Gobierno',
  'Manufactura / Distribución',
  'Restaurante / Alimentos',
  'Salud',
  'Servicios',
  'Servicios Profesionales',
  'Turismo',
  `Barras / Pub's / Discotecas`,
  'Servicios de Cita',
  'Venta al por Menor',
];
