import loader from '../../images/Loader.gif';
import { LoaderStyles } from './LoaderStyles';
import { LOADER_ALT_MSG } from '../../constants';

const useStyles = LoaderStyles();

const Loader = () => {
  const classes = useStyles();
  return (
    <div data-testid="loader_id">
      <img src={loader} className={classes.loader_circle} alt={LOADER_ALT_MSG} />
    </div>
  );
};

export default Loader;
