export const productMapping = (productName) => {
  const map = new Map();
  map.set('Cuenta Libre', 'Libre');
  map.set('Libre Account', 'Libre');
  // map.set('Cuenta Progresa', 'Progresa');
  // map.set('Progresa Account', 'Progresa');
  map.set('Cuenta Ahorra', 'Ahorra');
  map.set('Ahorra Account', 'Ahorra');
  map.set('Cuenta ABC', 'ABC');
  map.set('ABC Account', 'ABC');
  map.set('Cuenta Ahorro Club', 'Club');
  map.set('Ahorro Club Account', 'Club');
  map.set('Certificado de Deposito', 'Personal');
  map.set('Certificate of Deposit', 'Personal');
  map.set('CD IRA', 'CD IRA');
  map.set('IRA Facil', 'IRA Facil');
  map.set('Educational IRA', 'Educational IRA');
  map.set('IRA Educativa', 'Educational IRA');
  map.set('Annuity IRA', 'Annuity IRA');
  map.set('Junior Account', 'Junior Account');
  map.set('Easy Access Account', 'Easy Access');
  map.set('Complete Checking Account', 'Complete Checking');
  map.set('Oriental Savings Account', 'Oriental Savings');
  map.set('Certificates of Deposits', 'Personal CD');
  map.set('Progresa Contigo', 'Progresa Contigo');
  map.set('Elite Account', 'Elite Account');
  map.set('Cuenta Elite', 'Elite Account');

  if (productName?.toLowerCase() === 'IRA Fácil'.toLowerCase()) {
    map.set('IRA Fácil', 'IRA Facil');
  }

  return map.get(productName);
};
