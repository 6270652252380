export const countryEngTranslation = {
  Aruba: 'Aruba',
  Argentina: 'Argentina',
  Australia: 'Australia',
  Brazil: 'Brazil',
  Canada: 'Canada',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  'Costa Rica': 'Costa Rica',
  Cuba: 'Cuba',
  Germany: 'Germany',
  'Dominican Republic': 'Dominican Republic',
  Egypt: 'Egypt',
  Spain: 'Spain',
  France: 'France',
  'United Kingdom of Great Britain and Notherm Ireland':
    'United Kingdom of Great Britain and Notherm Ireland',
  Greece: 'Greece',
  Guatemala: 'Guatemala',
  'Hong Kong Special Administrative Regio of China':
    'Hong Kong Special Administrative Regio of China',
  Haiti: 'Haiti',
  Italy: 'Italy',
  Jamaica: 'Jamaica',
  Mexico: 'Mexico',
  "Democratic People's Republic of Korea": `Democratic People's Republic of Korea`,
  Portugal: 'Portugal',
  'Russian Federation': 'Russian Federation',
  'United States of America': 'United States of America',
  Venezuela: 'Venezuela',
  'United States Virgin Islands': 'United States Virgin Islands',
  Poland: 'Poland',
  'Channel Islands': 'Channel Islands',
  Afghanistan: 'Afghanistan',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Aland Islands': 'Aland Islands',
  Albania: 'Albania',
  Andorra: 'Andorra',
  'Netherlands Antilles': 'Netherlands Antilles',
  'United Arab Emirates': 'United Arab Emirates',
  Armenia: 'Armenia',
  'American Samoa': 'American Samoa',
  'Antigua and Barbuda': 'Antigua and Barbuda',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Burundi: 'Burundi',
  Belgium: 'Belgium',
  Benin: 'Benin',
  'Bonaire, Saint Eustatius and Saba': 'Bonaire, Saint Eustatius and Saba',
  'Burkina Faso': 'Burkina Faso',
  Bangladesh: 'Bangladesh',
  Bulgaria: 'Bulgaria',
  Bahrain: 'Bahrain',
  Bahamas: 'Bahamas',
  'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
  Belarus: 'Belarus',
  Belize: 'Belize',
  Bermuda: 'Bermuda',
  Bolivia: 'Bolivia',
  Barbados: 'Barbados',
  'Brunei Darussalam': 'Brunei Darussalam',
  Bhutan: 'Bhutan',
  Botswana: 'Botswana',
  'Central African Republic': 'Central African Republic',
  Switzerland: 'Switzerland',
  'Côte divoire': 'Costa de Marfil',
  Cameroon: 'Cameroon',
  'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
  Congo: 'Congo',
  'Cook Islands': 'Cook Islands',
  Comoros: 'Comoras',
  'Cape Verde': 'Cape Verde',
  Curacao: 'Curacao',
  'Cayman Islands': 'Cayman Islands',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Czech Republic',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  Denmark: 'Denmark',
  Algeria: 'Algeria',
  Ecuador: 'Ecuador',
  Eritrea: 'Eritrea',
  'Western Sahara': 'Western Sahara',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  Finland: 'Finland',
  Fiji: 'Fiji',
  'Falkland Islands (Malvinas)': 'Falkland Islands (Malvinas)',
  'Faeroe Islands': 'Faeroe Islands',
  'Federated States of Micronesia': 'Federated States of Micronesia',
  Gabon: 'Gabon',
  Georgia: 'Georgia',
  Guernsey: 'Guernsey',
  Ghana: 'Ghana',
  Gilbraltar: 'Gilbraltar',
  Guinea: 'Guinea',
  Guadeloupe: 'Guadeloupe',
  Gambia: 'Gambia',
  'Guinea-Bissau': 'Guinea-Bissau',
  'Equatorial Guinea': 'Equatorial Guinea',
  Grenada: 'Grenada',
  Greenland: 'Greenland',
  'French Guiana': 'French Guiana',
  Guam: 'Guam',
  Guyana: 'Guyana',
  Honduras: 'Honduras',
  Croatia: 'Croatia',
  Hungary: 'Hungary',
  Indonesia: 'Indonesia',
  'Isle of Man': 'Isle of Man',
  India: 'India',
  Ireland: 'Ireland',
  'Iran, Islamic Republic of': 'Iran, Islamic Republic of',
  Iraq: 'Iraq',
  Iceland: 'Iceland',
  Israel: 'Israel',
  Jersey: 'Jersey',
  Jordan: 'Jordan',
  Japan: 'Japan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kyrgyzstan: 'Kyrgyzstan',
  Cambodia: 'Cambodia',
  Kiribati: 'Kiribati',
  'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
  'Republic of Korea': 'Republic of Korea',
  Kuwait: 'Kuwait',
  "Lao People's Democratic Republic": "Lao People's Democratic Republic",
  Lebanon: 'Lebanon',
  Liberia: 'Liberia',
  'Libyan Arab Jamahiriya': 'Libyan Arab Jamahiriya',
  'Saint Lucia': 'Saint Lucia',
  Liechtenstein: 'Liechtenstein',
  'Sri Lanka': 'Sri Lanka',
  Lesotho: 'Lesotho',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Latvia: 'Latvia',
  'Macao Special Administrative Region of China': 'Macao Special Administrative Region of China',
  Morocco: 'Morocco',
  Monaco: 'Monaco',
  'Republic of Moldova': 'Republic of Moldova',
  Madagascar: 'Madagascar',
  Maldives: 'Maldives',
  'Marshall Islands': 'Marshall Islands',
  'The former Yugoslav Republic of Macedonia': 'The former Yugoslav Republic of Macedonia',
  Mali: 'Mali',
  Malta: 'Malta',
  Myanmar: 'Myanmar',
  Montenegro: 'Montenegro',
  Mongolia: 'Mongolia',
  'Northern Mariana Islands': 'Northern Mariana Islands',
  Mozambique: 'Mozambique',
  Mauritania: 'Mauritania',
  Montserrat: 'Montserrat',
  Martinique: 'Martinique',
  Mauritius: 'Mauritius',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Mayotte: 'Mayotte',
  Namibia: 'Namibia',
  'New Caledonia': 'New Caledonia',
  Niger: 'Niger',
  'Norfolk Island': 'Norfolk Island',
  Nigeria: 'Nigeria',
  Nicaragua: 'Nicaragua',
  Niue: 'Niue',
  Netherlands: 'Netherlands',
  Norway: 'Norway',
  Nepal: 'Nepal',
  Nauru: 'Nauru',
  'New Zealand': 'New Zealand',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Panama: 'Panama',
  Pitcaim: 'Pitcaim',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Palau: 'Palau',
  'Papa New Guinea': 'Papa New Guinea',
  'Puerto Rico': 'Puerto Rico',
  Paraguay: 'Paraguay',
  'Occupied Palestinian Territory': 'Occupied Palestinian Territory',
  'French Polynesia': 'French Polynesia',
  Qatar: 'Qatar',
  Reunion: 'Reunion',
  Romania: 'Romania',
  Rwanda: 'Rwanda',
  'Saudi Arabia': 'Saudi Arabia',
  Sudan: 'Sudan',
  Senegal: 'Senegal',
  Singapore: 'Singapore',
  'Saint Helena': 'Saint Helena',
  'Svalbard and Jan Mayen Islands': 'Svalbard and Jan Mayen Islands',
  'Solomon Islands': 'Solomon Islands',
  'Sierra Leone': 'Sierra Leone',
  'El Salvador': 'El Salvador',
  'San Marino': 'San Marino',
  Somalia: 'Somalia',
  'Saint Pierre and Miquelon': 'Saint Pierre and Miquelon',
  Serbia: 'Serbia',
  'Sao Tome and Principe': 'Sao Tome and Principe',
  Suriname: 'Suriname',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  Sweden: 'Sweden',
  Swaziland: 'Swaziland',
  'Sint Maarten': 'Sint Maarten',
  Seychelles: 'Seychelles',
  'Syrian Arab Republic': 'Syrian Arab Republic',
  'Turks and Caicos Islands': 'Turks and Caicos Islands',
  Chad: 'Chad',
  Togo: 'Togo',
  Thailand: 'Thailand',
  Tajikistan: 'Tajikistan',
  Tokelau: 'Tokelau',
  Turkmenistan: 'Turkmenistan',
  'Timor-Leste': 'Timor-Leste',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinidad and Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Tuvalu: 'Tuvalu',
  Taiwan: 'Taiwan',
  'United Republic of Tanzania': 'United Republic of Tanzania',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  'Holy See': 'Holy See',
  'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
  'British Virgin Islands': 'British Virgin Islands',
  'Viet Nam': 'Viet Nam',
  Vanuatu: 'Vanuatu',
  'Wallis and Futuna Islands': 'Wallis and Futuna Islands',
  Samoa: 'Samoa',
  Yemen: 'Yemen',
  'South Africa': 'South Africa',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe',
  'To which countries do you expect to make or receive your transfer?':
    'To which countries do you expect to make or receive your transfer?',
  'Select max of 10 countries': 'Select max of 10 countries',
  Search: 'Search',
  'Selected Countries ': 'Selected Countries ',
};
