import PropTypes from 'prop-types';
import TopHeader from './TopHeader';

export default function MainHeader(props) {
  const { setLanguage, firstName } = props;

  return (
    <div>
      <TopHeader setLanguage={setLanguage} firstName={firstName} />
      {/* <Grid item container xs={12} className={styledClasses.main_header}>
        <Grid item container xs={5}>
          <img
            src={OrientalImage}
            className={styledClasses.oriental}
            tabIndex="0"
            alt={MAIN_HEADER_LOGO}
            aria-label="Oriental Logo"
          />
        </Grid>
      </Grid> */}
    </div>
  );
}

MainHeader.propTypes = {
  firstName: PropTypes.string,
  setLanguage: PropTypes.func,
};
