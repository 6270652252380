import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { engTranslation } from './engTranslation';
import { espTranslation } from './espTranslation';
import { countryEspTranslation } from './countryEspTranslation';
import { countryEngTranslation } from './countryEngTranslation';

// Translation Configuration File for ENG and ESP
const resources = {
  ENG: {
    translation: { ...engTranslation, ...countryEngTranslation },
  },
  ESP: {
    translation: { ...espTranslation, ...countryEspTranslation },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'EN',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
