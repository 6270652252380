import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Typography, Modal, IconButton, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseIconIMG from '../../images/Icon.svg';
import classes from './SessionTimeoutModal.module.css';
import { CONTINUE, INACTIVEMODAL_TEXT_ONE, INACTIVE_MODAL_MSG } from '../../constants';

function InactivityModal({ setOpenInactivityModal, resetTimer, openModal }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpenInactivityModal(false);
    resetTimer();
  };

  useEffect(() => {
    if (openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  const textLineOne = INACTIVE_MODAL_MSG;

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      open={open}
      role="dialog"
      aria-describedby="dialog1Desc"
    >
      <div className={classes.sessionmodal}>
        <Grid container className={classes.sessioncontainer}>
          <IconButton
            src={CloseIconIMG}
            name="closeicon"
            onClick={handleClose}
            tabIndex="0"
            data-testid="inactivityModal_id"
            id="inactivityModal_id"
            disableRipple
            className={classes.closeOverlay}
          >
            <img
              alt={t(`close`, { defaultValue: 'close' })}
              tabIndex="0"
              src={CloseIconIMG}
              width="20px"
              height="20px"
            />
          </IconButton>
        </Grid>
        <Grid container>
          <Card className={classes.inactivityModalRoot}>
            <CardContent className={classes.card_content}>
              <Typography className={classes.textOneInactiveStyle} id="dialog1Desc">
                {t(INACTIVEMODAL_TEXT_ONE, { defaultValue: textLineOne })}
              </Typography>
              <Button
                disableFocusRipple
                autoFocus
                className={classes.continueAppButton}
                onClick={handleClose}
              >
                {t(CONTINUE, { defaultValue: CONTINUE })}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Modal>
  );
}

export default withRouter(InactivityModal);

InactivityModal.propTypes = {
  setOpenInactivityModal: PropTypes.func,
  resetTimer: PropTypes.func,
  openModal: PropTypes.bool,
};
