import axios from 'axios';

export const ping = async (accessToken) => {
  try {
    await axios.get(process.env.REACT_APP_PING_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    return e;
  }
  return 0;
};
